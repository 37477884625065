export default {
    namespaced: true,
    state: {
        userAuth:{},
    },
    getters: {
        userAuth: state => state.userAuth,
    },
    mutations: {
        update_userAuth(state, value) {
            state.userAuth = {...state.userAuth,...value}
        }
    }

}