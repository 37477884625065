export default {
  namespaced: true,
  state: {
    current_cache_tab_list: ["home"],
    current_tab_list: [
      {
        path: "/monitoring_center/operation_overview",
        name: "home",
        title: "运营总览",
        id: -1,
      },
    ],
  },
  getters: {
    current_tab_list: (state) => state.current_tab_list,
    current_cache_tab_list: (state) => state.current_cache_tab_list,
  },
  mutations: {
    // 删除tag:删除tabList中对应的item
    closeTag(state, item) {
      // 要删除的是state.tabList中的item
      const index = state.current_tab_list.findIndex(
        (val) => val.path === item.path
      );
      state.current_tab_list.splice(index, 1);
    },
    add_current_tab_list(state, value) {
      console.log("执行添加");
      const found = state.current_tab_list.some(
        (tab) => tab.path === value.path
      );
      if (!found) {
        state.current_tab_list.push(value);
      }
      
    },
    add_current_cache_tab_list(state, value) {
      console.log("执行add_current_cache_tab_list111", state);
      console.log("执行add_current_cache_tab_list222", value);
      if (!state.current_cache_tab_list.includes(value)) {
        state.current_cache_tab_list.push(value);
      }
    },

  },
};
