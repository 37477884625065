import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "reset-css";
import Vue from "vue";
import App from "./App.vue";
import commonFunc from "./commonFunc";
import "./fonts/fonts/font.css";
import myDirective from "./myDirective.js";
import router from "./router";
import store from "./store";
// import SocketService from './utils/websocket'
//引入echarts
import "@fortawesome/fontawesome-free/css/all.css";
import * as echarts from "echarts";
//vue全局注入echarts
Vue.prototype.$echarts = echarts;

// 全局引入上传图片
import EleUploadImage from "vue-ele-upload-image";
Vue.component(EleUploadImage.name, EleUploadImage);

// 引入flexible.js
import "./utils/flexible.js";

Vue.prototype.commonFunc = commonFunc;

// 地图
Vue.prototype.$Map = window.TMap;

// 全局 处理重复点击问题
import Router from "vue-router";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 对服务器进行websocket的连接
// SocketService.Instance.connect()
// 其他组件的连接
// Vue.prototype.$socket = SocketService.Instance

// Vue.config.errorHandler = function (err, vm, info) {
//   // 处理错误，例如记录日志或显示提示信息
//   console.error('发生错误:', err);
// };

// 禁用webpack-dev-server的overlay插件默认行为
// if (module.hot) {
//   module.hot.dispose(() => {
//     // 清除错误提示
//     console.clear();
//   });
//   module.hot.accept(() => {
//     // 重新加载应用
//     window.location.reload();
//   });
// }

//用于在生产环境中禁用 Vue.js 的启动提示信息
Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(myDirective);

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
}).$mount("#app");
